import { css, Global } from '@emotion/react';
import * as React from 'react';
import { HomeBackground } from '../components/home/home-background';
import { HomeComputer } from '../components/home/home-computer';
import { EmptyLayout } from '../layouts/empty';
import cursorImageUrl from '../images/home/cursor.png';

const IndexPage = () => {
    return (
        <EmptyLayout>
            <Global
                styles={css`
                    * {
                        cursor: url(${cursorImageUrl}), auto;
                    }
                `}
            />
            <HomeBackground>
                <HomeComputer />
            </HomeBackground>
        </EmptyLayout>
    );
};

export default IndexPage;

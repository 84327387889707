import styled from '@emotion/styled';
import backgroundPatternImage from '../../images/home/background-pattern.png';

export const HomeBackground = styled.div`
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }

    background-image: url(${backgroundPatternImage});
    background-repeat: repeat;
    background-size: 700px 700px;
    animation-name: move;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    overflow: hidden;

    @keyframes move {
        0% {
            background-position: 0px 0px;
        }

        100% {
            background-position: -700px -700px;
        }
    }
`;

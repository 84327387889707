import 'normalize.css';
import '@fontsource/noto-sans-kr';

import React, { FC, ReactNode, useMemo } from 'react';
import { DefaultStyle } from '../styles/default';
import { ThemeProvider } from '@emotion/react';
import { Theme, ThemeType } from '../styles/theme';
import { match } from 'ts-pattern';

export const EmptyLayout: FC<{ children: ReactNode }> = ({ children }) => {
    // Define theme properties
    const lightTheme: Theme = {
        colors: {
            text: '28,28,28',
            textLight: '97,97,97',
            textInverted: '255,255,255',
            background: '255,255,255',
            highlight: '255,230,0',
            textHighlight: '28,28,28',
        },
    };

    const darkTheme: Theme = {
        colors: {
            text: '255,255,255',
            textLight: '238,238,238',
            textInverted: '28,28,28',
            background: '28,28,28',
            highlight: '255,230,0',
            textHighlight: '28,28,28',
        },
    };

    const savedTheme = useMemo(() => {
        const savedTheme =
            typeof window !== 'undefined'
                ? (localStorage.getItem('theme') as ThemeType | undefined)
                : undefined;

        return savedTheme ?? 'light';
    }, []);

    return (
        <ThemeProvider
            theme={match(savedTheme)
                .with('dark', () => darkTheme)
                .with('light', () => lightTheme)
                .exhaustive()}
        >
            <DefaultStyle />
            {children}
        </ThemeProvider>
    );
};
